import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link, Navigate } from 'react-router-dom';
import { DeviceContext } from '../contexts/DeviceContext';
import { useSession } from '../contexts/SessionContext';

import Container from '../components/Container';
import Icon from '../components/Icon';
import Button from '../components/Button';

import backgroundImg from '../assets/images/landingImg-min.png';
import wifiIcon from '../assets/icons/icon-wifi.png';

import UrlParamsContext from "../contexts/URLparamsContext";

const Background = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${backgroundImg});
  background-repeat: no-repeat;
  background-position: right;
  background-size: cover;
  position: fixed;
  display: flex;
  flex-direction: column;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  color: white;
  align-items: flex-start;
`;

const Title = styled.div`
  font-weight: 700;
  font-size: 6.25rem;

  @media screen and (max-width: 768px) {
    font-size: 2.5rem;
  }

  @media screen and (min-width: 768px) {
    font-size: 4.5rem;
  }

  @media screen and (min-width: 1200px) {
    font-size: 5rem;
  }
`;
const Title2 = styled.div`
  font-weight: 700;
  font-size: 4.25rem;

  @media screen and (max-width: 768px) {
    font-size: 2.5rem;
  }

  @media screen and (min-width: 768px) {
    font-size: 3.5rem;
  }

  @media screen and (min-width: 1200px) {
    font-size: 4rem;
  }
`;
const Description = styled.div`
  font-weight: 100;
  font-size: 3rem;
  padding-right: 0.5rem;

  @media screen and (max-width: 768px) {
    font-size: 1.75rem;
  }

  @media screen and (min-width: 768px) {
    font-size: 2.5rem;
  }

  @media screen and (min-width: 1200px) {
    font-size: 3rem;
  }
`;

const Description2 = styled.div`
  font-weight: 100;
  font-size: 2rem;
  padding-right: 0.5rem;

  @media screen and (max-width: 768px) {
    font-size: 1.75rem;
  }

  @media screen and (min-width: 768px) {
    font-size: 2.5rem;
  }

  @media screen and (min-width: 1200px) {
    font-size: 3rem;
  }
`;

const FlexBlock = styled.div`
  display: flex;
  padding-top: 2%;
  padding-bottom: 2%;
  align-items: center;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;

function Home() {
  const { setUrlParams } = useContext(UrlParamsContext);
  const { isMobile } = useContext(DeviceContext);
  const { session } = useSession();
  const [noParams, setNoParams] = useState(false); 

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const params = Object.fromEntries(queryParams.entries());
    setUrlParams(params);
    setNoParams(Object.keys(params).length === 0); 
  }, [setUrlParams]);

  if (session) {
    return <Navigate to="/welcome" />;
  }

  if (noParams) {
    return (
      <Background>
        <Container>
          <ContentWrapper>
            <Title2>The website is available exclusively to users connected to the Wi-Fi network at Skopje Airport TAV.</Title2>
            <Description2>
             If you are a visitor or user, please connect to the airport Wi-Fi on your device, and you’ll be automatically redirected.            </Description2>
          </ContentWrapper>
        </Container>
      </Background>
    );
  }


  return (
    <Background>
      {isMobile ? (
        <iframe
          src="https://ads.skpairport.mk/www/delivery/afr.php?zoneid=9"
          style={{ width: '100%', height: '130px', border: 'none', overflow: 'hidden' }}
        />
      ) : (
        <iframe
          src="https://ads.skpairport.mk/www/delivery/afr.php?zoneid=1"
          style={{ width: '100%', border: 'none', overflow: 'hidden' }}
          scrolling="no"
        />
      )}
      <Container>
        <ContentWrapper>
          <Title>WELCOME TO <br /> SKOPJE AIRPORT</Title>
          <FlexBlock>
            <Description>GET <strong>FREE WI-FI</strong> NOW</Description>
            <Icon src={wifiIcon} alt="Icon" />
          </FlexBlock>
          <StyledLink to="/login">
            <Button>CONNECT NOW</Button>
          </StyledLink>
        </ContentWrapper>
      </Container>
    </Background>
  );
}

export default Home;
